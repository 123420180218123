import {getAuth} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'

import {FIREBASE_CONFIG} from '../constants'
initializeApp(FIREBASE_CONFIG)

export const auth = getAuth()
export const db = getFirestore()
export const storage = getStorage()