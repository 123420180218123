import {toast} from 'react-toastify'
import {API_URL} from '../constants'
import {auth} from './firebase'
import axios from 'axios'

/**
 *
 * @param {string} message Message
 * @param {'success'|'error'} type Message type
 * @returns null
 */
export const showMessage = (message, type = 'error') => {

	toast[type](message, {
		autoClose: 2500,
		draggable: false,
		closeOnClick: true,
		pauseOnHover: true
	})

	return null

}

/**
 * XHR Request
 * @param {string} url URL where the request is performed
 * @param {object} data Additional request data
 * @param {boolean} needsAuth Should request send authorization headers
 */
 export const request = async (url, data = {}, needsAuth = true) => {

	let options = {}

	if (needsAuth) {

		// get current user
		const {currentUser} = auth

		if (!currentUser)
			return {error: 'not_authenticated'}

		// set token
		options.headers = {Authorization: await currentUser.getIdToken()}

	}

	let response = await axios.post(API_URL + url, data, options)
		.then(response => response.data)
		.catch(() => ({error: 'api_failed'}))

	if (typeof response !== 'object')
		response = {error: 'api_failed'}

	return response

}

export const colorShade = (col, amt) => {

	col = col.replace(/^#/, '')
	if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

	let [r, g, b] = col.match(/.{2}/g);
	([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

	r = Math.max(Math.min(255, r), 0).toString(16)
	g = Math.max(Math.min(255, g), 0).toString(16)
	b = Math.max(Math.min(255, b), 0).toString(16)

	const rr = (r.length < 2 ? '0' : '') + r
	const gg = (g.length < 2 ? '0' : '') + g
	const bb = (b.length < 2 ? '0' : '') + b

	return `#${rr}${gg}${bb}`

}

export const ucwords = str => (str + '').replace(/^(.)|\s+(.)/g, $1 => $1.toUpperCase())