import {db, auth, storage} from '../utils/firebase'
import {showMessage, request} from '../utils'

import {
	query,
	where,
	limit,
	doc,
	orderBy,
	getDoc,
	getDocs,
	startAfter,
	endBefore,
	onSnapshot,
	collection,
	updateDoc,
	deleteDoc
} from 'firebase/firestore'

import {
	getDownloadURL,
	ref as storageRef,
	uploadBytesResumable
} from 'firebase/storage'

import {
	REPLIES,
	REPLIES_RESET,
	REPLIES_LOADED,
	REPLIES_LOADING,
	REPLIES_NEW_ITEM,
	REPLIES_REMOVE_ITEM,
	REPLIES_LOADED_ALL,
	REPLIES_LOADED_MORE,
	REPLIES_PER_REQUEST,
  TICKETS,
	TICKETS_LOADED,
	TICKETS_LOADING,
	TICKETS_RESET,
	TICKETS_STATUS,
	TICKETS_NEW_ITEM,
	TICKETS_UPDATED_ITEM,
	TICKETS_REMOVE_ITEM,
	TICKETS_LOADED_ALL,
	TICKETS_LOADED_MORE,
  TICKETS_PER_REQUEST,
	USER_LOADING,
} from '../constants'

export const unsubscribe = {}

/**
 * Get previously created tickets
 * @param {string} ticketId Unique identifier
 * @param {function} callback Callback trigger
 * @returns {array} List of tickets without specified ticket id
 */
export const getPreviousTickets = (ticketId, callback) => async (dispatch, getState) => {

	const {account, tickets} = getState()
	const ticket = tickets.list.find(item => item.objectId === ticketId)

	if (!ticket)
		return false

	// set loading
	callback({loading: true})

	// create query
	let q = query(
		collection(db, TICKETS),
		where('account', '==', account.id),
		where('owner', '==', ticket.owner),
		orderBy('lastReplyAt', 'desc'),
		limit(TICKETS_PER_REQUEST)
	)

	// get tickets
	getDocs(q)
		.then(snapshot => {

			if (snapshot.empty)
				return false

			// set tickets
			callback({previousTickets: snapshot.docs.map(doc => doc.data())})

		})
		.catch(err => showMessage(err.message))
		.finally(() => callback({loading: false}))
}

/**
 * Fetch tickets
 * @param {('open'|'closed')} status Ticket status
 */
export const fetchTickets = (status, forceRefresh = null) => (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	const {account, tickets} = getState()

	// did status change, update and reset list
	if (status !== tickets.status) {

		dispatch({
			type: TICKETS_STATUS,
			data: status
		})

		// reset list
		tickets.list = []

	} else if (!forceRefresh && (tickets.loading || tickets.noMoreToLoad))
		return false

	// reset tickets
	if (forceRefresh) {
		dispatch({type: TICKETS_RESET})
		tickets.list = []
	}

	dispatch({type: TICKETS_LOADING})

	let q = query(
		collection(db, TICKETS),
		where('account', '==', account.id),
    where('status', '==', status),
		orderBy('lastReplyAt', 'desc')
	)

	if (tickets.list.length) {

		q = query(q,
			startAfter(tickets.list[tickets.list.length - 1].createdAt),
			limit(TICKETS_PER_REQUEST)
		)

		return getDocs(q).then(snapshot => {

			if (snapshot.empty)
				return dispatch({type: TICKETS_LOADED_ALL})

			let items = snapshot.docs.map(doc => doc.data())

			dispatch({
				type: TICKETS_LOADED_MORE,
				data: items
			})

			if (items.length < TICKETS_PER_REQUEST)
				dispatch({type: TICKETS_LOADED_ALL})

		})

	} else {

		q = query(q, limit(TICKETS_PER_REQUEST))

		return getDocs(q).then(snapshot => {

			if (!snapshot.empty)
				q = query(q, endBefore(snapshot.docs[0]))

			if (unsubscribe.tickets)
				unsubscribe.tickets()

			unsubscribe.tickets = onSnapshot(q, snapshot =>
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added')
						dispatch({
							type: TICKETS_NEW_ITEM,
							data: change.doc.data()
						})
				})
			)

			if (snapshot.empty) {
				dispatch({type: TICKETS_LOADED_ALL})
				return false
			}

			let items = snapshot.docs.map(doc => doc.data())

			dispatch({
				type: TICKETS_LOADED,
				data: items
			})

			if (items.length < TICKETS_PER_REQUEST)
				dispatch({type: TICKETS_LOADED_ALL})

		})

	}

}

/**
 * Ticket reply fetch
 * @param {string} ticketId Unique identifier
 * @param {boolean} forceRefresh Should replies be emptied
 */
export const fetchReplies = (ticketId, forceRefresh = false) => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	const {replies} = getState()

	// cand avem forceRefresh = true, dam dispatch la un eveniment nou
	if (forceRefresh)
		dispatch({type: REPLIES_RESET})

	if (replies.loading || replies.noMoreToLoad)
		return false

	dispatch({type: REPLIES_LOADING})

	let q = query(
		collection(db, TICKETS, ticketId, REPLIES),
		orderBy('createdAt', 'desc'),
		limit(REPLIES_PER_REQUEST)
	)

	if (replies.list.length) {

		q = query(q,
			startAfter(replies.list[0].createdAt),
			limit(REPLIES_PER_REQUEST)
		)

		return getDocs(q).then(snapshot => {

			if (snapshot.empty)
				return dispatch({type: REPLIES_LOADED_ALL})

			let items = snapshot.docs.map(doc => doc.data())

			dispatch({
				type: REPLIES_LOADED_MORE,
				data: items.reverse()
			})

			if (items.length < REPLIES_PER_REQUEST)
				dispatch({type: REPLIES_LOADED_ALL})

		})

	} else {
		q = query(q, limit(REPLIES_PER_REQUEST))

		return getDocs(q).then(snapshot => {

			if (!snapshot.empty)
				q = query(q, endBefore(snapshot.docs[0]))

			if (unsubscribe.replies)
				unsubscribe.replies()

			unsubscribe.replies = onSnapshot(q, snapshot =>
				snapshot.docChanges().forEach(change => {

					if (change.type === 'added')
						dispatch({
							type: REPLIES_NEW_ITEM,
							data: change.doc.data()
						})

				})
			)

			if (snapshot.empty) {
				dispatch({type: REPLIES_LOADED_ALL})
				return false
			}

			let items = snapshot.docs.map(doc => doc.data())

			dispatch({
				type: REPLIES_LOADED,
				data: items.reverse()
			})

			if (items.length < REPLIES_PER_REQUEST)
				dispatch({type: REPLIES_LOADED_ALL})

		})

	}

}

/**
 * Create a support ticket
 * @param {object} data Ticket details
 * @param {function} navigate Navigator
 */
export const createTicket = (data, navigate) => async (dispatch, getState) => {

	const {account} = getState()

	if (!data.subject.trim().length || !data.message.trim().length)
		return showMessage('All fields required')

	dispatch({
		type: USER_LOADING,
		data: true
	})

	let response = await request('/support/create', {...data, accountId: account.id})

	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (response.error)
		return showMessage(response.error)

	navigate(`/tickets/${response.ticketId}`)

}

/**
 * Fetch ticket data
 * @param {string} objectId Unique identifier
 * @param {function} navigate Navigator
 * @param {function} callback On-success trigger
 */
export const fetchTicket = (objectId, navigate, callback) => async (dispatch, getState) => {

	// get all loaded owners
	const {tickets} = getState()

	// check if customer is already loaded
	let exists = tickets.list.find(item => item.objectId === objectId)

	if (exists)
		return callback(false)

	let ticket = await getDoc(doc(db, TICKETS, objectId))
		.then(snapshot => snapshot.exists() ? snapshot.data() : {})
		.catch(() => ({}))

	if (!ticket.objectId)
		return navigate('/', {replace: true})

	dispatch({
		type: TICKETS_LOADED_MORE,
		data: [ticket]
	})

	callback(true)

}

/**
 * Remove ticket data from memory
 * @param {string} ticketId Unique identifier
 */
export const unloadTicket = ticketId => async (dispatch, getState) => {

	const {tickets} = getState()

	if (tickets.list.length === 1)
		dispatch(fetchTickets(tickets.status, true))
	else
		dispatch({
			type: TICKETS_REMOVE_ITEM,
			data: ticketId
		})

}

/**
 * Ticket status toggler
 * @param {string} ticketId Unique identifier
 * @param {'open'|'closed'} status Ticket status
 * @param {function} navigate Navigator
 */
export const toggleStatus = (ticketId, status, navigate) => async dispatch => {

	if (status === 'closed' && !window.confirm('Are you sure you want to close this ticket?'))
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})

	updateDoc(doc(db, TICKETS, ticketId), {status})
		.then(() => {
			navigate('/', {replace: true})
			showMessage('Status has been updated', 'success')
			dispatch({
				type: TICKETS_REMOVE_ITEM,
				data: ticketId
			})
		}).catch(() => {
			showMessage('Failed updating ticket status')
		})
		.finally(() => {
			dispatch({
				type: USER_LOADING,
				data: false
			})
		})

}

/**
 * Ticket reply
 * @param {object} data Message details
 * @param {function} callback On success trigger
 */
export const reply = (data, callback) => async dispatch => {

	dispatch({
		type: USER_LOADING,
		data: true
	})

	const send = async fields => {

		let response = await request('/support/reply', fields)

		dispatch({
			type: USER_LOADING,
			data: false
		})

		if (response.error)
			return showMessage(response.error)

		callback({
			message: '',
			progress: 0,
			attachment: null
		})

		showMessage('Success', 'success')

	}

	if (data.attachment) {

		let key = doc(collection(db, TICKETS)).id,
			fileRef = storageRef(storage, [TICKETS, data.objectId, key + (data.attachment.type.startsWith('image') ? '.jpg' : '.mp4')].join('/')),
			metadata = {
				contentType: data.attachment.type,
				cacheControl: 'public, max-age=31540000'
			}

		// upload file
		const uploadTask = uploadBytesResumable(fileRef, data.attachment, metadata)

		// run listener
		uploadTask.on('state_changed',
			snapshot => {

				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
				callback({progress: Math.floor(progress)})

				switch (snapshot.state) {
					case 'paused':
						showMessage('Upload is paused, please wait')
						break
					default:
						return false
				}

			}, () => {
				showMessage('An error occurred while uploading, try again')
				dispatch({
					type: USER_LOADING,
					data: false
				})
			}, async () => {
				data.attachment = await getDownloadURL(uploadTask.snapshot.ref).then(url => url)
				send(data)
			}
		)


	} else
		send(data)

}

/**
 * Remove a reply
 * @param {object} ticket Ticket details
 * @param {object} reply Reply details
 */
export const removeReply = (ticket, reply) => async (dispatch, getState) => {

	const {replies} = getState()

	if (reply.objectId !== replies.list[replies.list.length - 1].objectId || !reply.operator)
		return false

	if (!window.confirm('Are you sure? This action cannot be undone'))
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})

	deleteDoc(doc(db, TICKETS, ticket.objectId, REPLIES, reply.objectId))
		.then(() => {

			showMessage(`Successfully removed`, 'success')

			dispatch({
				type: REPLIES_REMOVE_ITEM,
				data: reply.objectId
			})

		// get previous reply
		let previous = replies.list[replies.list.length - 2] || {}

		if (previous.objectId)
			dispatch({
				type: TICKETS_UPDATED_ITEM,
				data: {
					objectId: ticket.objectId,
					lastReply: previous.message,
					lastReplyAt: previous.createdAt,
					lastReplyBy: previous.operator || ticket.owner
				}
			})

		}).catch(() => {
			showMessage('Failed removing message')
		})
		.finally(() => {
			dispatch({
				type: USER_LOADING,
				data: false
			})
		})

}
