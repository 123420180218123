import {useDispatch, useSelector} from 'react-redux'
import parsePhoneNumber from 'libphonenumber-js'
import {Link, useParams} from 'react-router-dom'
import {Timestamp} from 'firebase/firestore'
import {useState, useEffect} from 'react'
import moment from 'moment'

import {
	CUSTOMERS,
	FIREBASE_CONFIG
} from '../../../constants'

import {
	refund,
	fetchBatch,
	fetchCustomer
} from '../../../actions/user'

export default function Customer() {

	const dispatch = useDispatch()
	const {objectId} = useParams()

	const account = useSelector(state => state.account)
	const loading = useSelector(state => state.user.loading)

	const [state, changeState] = useState({
		loading: false,
		customer: {
			billing: {},
			subscription: {}
		},
		transactions: [],
		noMoreToLoad: false
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	// fetch profile info
	useEffect(() => {

		(async () => {

			// get profile data
			let data = await dispatch(fetchCustomer(objectId))

			// check if it exists
			if (!data.objectId)
				return setState({nonexistent: true})

			// check if it was removed
			if (!!data.removedAt)
				return setState({removed: true})

			// save data
			setState({customer: data})

		})()

	}, [objectId, dispatch, account.id])

	const loadMore = async () => {

		if (state.loading)
			return false

		setState({loading: true})

		let result = await dispatch(fetchBatch({
			collection: 'transactions',
			field: 'owner',
			value: objectId,
			orderBy: 'createdAt',
			sortType: 'desc',
			last: state.transactions[state.transactions.length - 1]
		})) || {}

		setState({
			loading: false,
			transactions: [...state.transactions, ...result.list],
			noMoreToLoad: result.noMoreToLoad
		})

	}

	const onRefund = async item => {

		if (loading)
			return false

		dispatch(refund(item, (transactionId, refundTransaction = null) => {

			let transactions = [...state.transactions]

			if (refundTransaction)
				transactions.unshift({
					...refundTransaction,
					createdAt: Timestamp.now()
			})

			// update refunded transaction
			transactions = transactions.map(transaction => {

				if (transaction.objectId === transactionId)
					return {
						...transaction,
						refunded: true
					}

				return transaction

			})

			setState({transactions})

		}))

	}

	if (state.removed)
		return (
			<div className="alert alert-danger my-3">
				This customer has been removed on {moment(state.customer.removedAt?.toDate()).format('Do MMMM, YYYY')}. Click <Link className="alert-link" to="/customers">here</Link> to go back to the list
			</div>
		)

	if (state.nonexistent)
		return (
			<div className="alert alert-danger my-3">
				Customer does not exist. Click <Link className="alert-link" to="/customers">here</Link> to go back to the list
			</div>
		)

	// destructure customer data
	const {customer} = state

	// check if customer data is loaded
	if (!customer.objectId)
		return (
			<div className="box text-center p-3">
				<i className="fas fa-2x fa-spin fa-spinner-third" />
			</div>
		)

	// get subscription data (if any)
	const {subscription} = customer

	return (
		<>

			<div className="box noTop p-3 mb-3">

				<h4 className="mb-1">{customer.billing.holder || 'Customer'}</h4>
				{customer.billing.type === 'company' && <small className="text-muted">Org ID {customer.billing.orgId}</small>}

				<div className="mt-2">
					<span className="badge bg-secondary text-white me-2">
						{customer.billing.type}
					</span>
					<span className={'badge me-2 bg-' + (customer.cardLinked ? 'success' : (customer.cardLinked === null ? 'warning' : 'danger'))}>
						{customer.cardLinked ? 'cc linked' : (customer.cardLinked === null ? 'card removed' : 'no card')}
					</span>
				</div>

				<hr/>

				<div className="row">
					<div className="col-12 col-md-6">
						<b>Active since:</b> {customer.createdAt ? moment(customer.createdAt.toDate()).format('MMM D, HH:mm') : 'loading..'}
						<br/>
						{customer.billing.type === 'company'
							? <><b>Email:</b> {customer.contact.email} <span className="text-muted">({customer.contact.name})</span></>
							: <><b>Email:</b> {customer.billing.email}</>
						}
						<br/>
						<b>Phone:</b> {parsePhoneNumber(customer.phoneNumber).formatInternational()}
					</div>
					<div className="col-12 col-md-6">
						<b>Stripe:</b> {customer.stripeId ? <Link target="_blank" to={`//dashboard.stripe.com/customers/${customer.stripeId}`}>{customer.stripeId}</Link> : 'N/A'}
						<br/>
						<b>UID:</b> <Link target="_blank" to={`//console.firebase.google.com/project/${FIREBASE_CONFIG.projectId}/firestore/data/~2F${CUSTOMERS}~2F${customer.objectId}`}>
							{customer.objectId.slice(0, 5) + '...' + customer.objectId.slice(-5)}
						</Link>
					</div>
				</div>

			</div>

			<div className="row my-3">

				{/* SUBSCRIPTION */}
				<div className="col-12 col-md-6">
					<ul className="nav nav-tabs">
						<li className="nav-item">
							<span className="nav-link active fw-bold">
								{customer.pickup ? 'Pickup' : 'Subscription'}
							</span>
						</li>
					</ul>
					<div className="box noTop mb-3 p-3">

						{subscription?.account === account.id ?
							<>
								<div className="card mb-3">
									<div className="card-header fw-bold">Details</div>
									<table className="table mb-0">
										<tbody>
											<tr>
												<td className="text-muted">Drivers</td>
												<td>{customer.drivers.length}</td>
											</tr>
											<tr>
												<td className="text-muted">Extra battery</td>
												<td>{customer.drivers[0].battery ? 'YES' : 'NO'}</td>
											</tr>
											<tr>
												<td className="text-muted">Price</td>
												<td>{subscription.price} {subscription.currency} {customer.billing.type === 'company' ? `+ ${(subscription.price * account.vat / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} VAT` : ''}</td>
											</tr>
											<tr>
												<td className="text-muted">Started on</td>
												<td>{moment(subscription.createdAt.toDate()).format('MMM D, YYYY')}</td>
											</tr>
											<tr>
												<td className="text-muted">Renews on</td>
												<td>{moment(subscription.rebillAt.toDate()).format('MMM D, YYYY')}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
							:
							(customer.pickup?.account === account.id ?
								<div className="text-muted">Customer has a scheduled pickup</div>
								:
								<div className="text-muted">No active subscription in this account</div>
							)
						}

						{!!customer.drivers &&
							<>
								{customer.drivers.map((driver, i) => {

									let key = `driver_${i}`

									return (
										<div key={driver.name} className="card mt-3">
											<div className="card-header fw-bold d-flex align-items-center justify-content-between" onClick={() => setState({[key]: !state[key]})}>
												{driver.name}
												{state[key]
													? <i className="fas fa-minus"></i>
													: <i className="fas fa-plus"></i>
												}
											</div>
											{state[key] &&
												<table className="table mb-0">
													<tbody>
														<tr>
															<td className="text-muted">License</td>
															<td>{driver.license}</td>
														</tr>
														<tr>
															<td className="text-muted">Insurance</td>
															<td>{driver.nino}</td>
														</tr>
														<tr>
															<td className="text-muted">Postcode</td>
															<td>{driver.zip}</td>
														</tr>
													</tbody>
												</table>
											}
										</div>
									)
								}
								)}
							</>
						}

					</div>
				</div>

				{/* SUBSCRIPTION */}
				<div className="col-12 col-md-6">

					<ul className="nav nav-tabs">
						<li className="nav-item">
							<span className="nav-link active fw-bold">Transactions</span>
						</li>
					</ul>

					<div className="box noTop mb-3">
						<div className="list-group-flush p-0">
							{state.transactions.map(item => (
								<div className="list-group-item d-flex align-items-center" key={item.objectId}>
									<div className="text-muted mb-1">
										<Link target="_blank" to={`//console.firebase.google.com/project/${FIREBASE_CONFIG.projectId}/firestore/data/~2Ftransactions~2F${item.objectId}`}>
											{item.objectId.slice(0, 5)}...{item.objectId.slice(-5)}
										</Link>
										<br/>
										{(item.paymentId && item.description !== 'refund') &&
											<button className={'btn btn-xs me-1 ' + (item.refunded ? 'btn-secondary' : 'btn-danger')} onClick={() => onRefund(item)} disabled={loading || item.refunded}>
												{loading
													? <i className="fas fa-spin fa-spinner-third" />
													: <i className="fas fa-fw fa-undo-alt" />
												}
											</button>
										}
										<span className="badge bg-secondary">{item.description}</span>
									</div>
									<div className="separator" />
									<div className="text-end">
										<b className="text-muted">{item.amount.toLocaleString(undefined, {maximumFractionDigits: 2})} {item.currency}</b>
										<br/>
										{moment(item.createdAt.toDate()).format('MMM D, HH:mm')}
									</div>
								</div>
							))}

							{(!state.transactions.length && state.noMoreToLoad) &&
								<div className="text-danger p-3">No transactions to show yet</div>
							}
						</div>

						{!state.noMoreToLoad &&
							<div className="text-center py-3">
								<button className="btn btn-sm btn-primary" onClick={loadMore} disabled={state.loading}>
									{state.loading
										? <i className="fas fa-spin fa-spinner-third" />
										: (state.transactions.length ? 'load more' : 'load transactions')
									}
								</button>
							</div>
						}
					</div>

				</div>
			</div>

		</>
	)

}
