import thunk from 'redux-thunk'
import {
  createStore,
  applyMiddleware,
  combineReducers
} from 'redux'

import account from './account'
import tickets from './tickets'
import replies from './replies'
import user from './user'

const reducers = {
  account,
	tickets,
  replies,
  user
}

// Store
export default createStore(
	combineReducers(reducers),
	applyMiddleware(thunk)
)