import {
	USER_LOADING,
	USER_LOGGED_IN,
	USER_LOGGED_OUT
} from '../constants'

const initialState = {
	loading: true
}

export default function User(state = initialState, action) {
	switch (action.type) {
		case USER_LOGGED_IN:
			return {
				...state,
				loading: false,
				...action.data
			}
		case USER_LOGGED_OUT:
			return {
				...initialState,
				loading: false
			}
		case USER_LOADING:
			return {
				...state,
				loading: action.data
			}
		default:
			return state
	}
}