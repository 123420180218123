import {useDispatch, useSelector} from 'react-redux'
import {login} from '../../actions/user'
import {useState, useRef} from 'react'

export default function Connect() {

	const dispatch = useDispatch()

	const passwordInput = useRef()
	const user = useSelector(state => state.user)

	const [state, changeState] = useState({
		account: localStorage.getItem('account') || '',
		username: '',
		password: ''
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onInputChange = e => setState({[e.target.name]: e.target.name === 'password' ? e.target.value : e.target.value.toLowerCase().replace(/[^a-z0-9-]+/g, '')})
	const onSubmit = () => dispatch(login({...state}))
	const onKeyDown = e => {

		if (e.which !== 13 || !state.account.trim().length || !state.username.trim().length)
			return false

		if (!state.password.trim().length)
			return passwordInput.current.focus()

		return onSubmit()

	}

	return (
		<div className="container-fluid">
			<div className="connect">

				<input
					type="text"
					name="account"
					value={state.account}
					placeholder="Account"
					className="form-control mb-2"
					maxLength={20}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
				/>

				<input
					type="text"
					name="username"
					value={state.username}
					placeholder="Username"
					className="form-control mb-2"
					maxLength={40}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
				/>

				<input
					type="password"
					name="password"
					ref={passwordInput}
					value={state.password}
					placeholder="Password"
					className="form-control mb-4"
					maxLength={30}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
				/>

				<div className="d-grid">
					<button className="btn btn-success" onClick={onSubmit} disabled={user.loading || !state.username.length || !state.password.length}>
						{user.loading ? <i className="fas fa-spin fa-spinner-third" /> : 'CONNECT'}
					</button>
				</div>

			</div>
		</div>
 )

}