import {db, auth} from '../utils/firebase'
import moment from 'moment'

import {
	request,
	showMessage
} from '../utils'

import {
	signOut,
	onAuthStateChanged,
	signInWithCustomToken
} from 'firebase/auth'

import {
	doc,
	getDoc,
	getDocs,
	collection,
	query,
	limit,
	where,
	orderBy,
	startAfter,
	updateDoc,
	increment
} from 'firebase/firestore'

import {

	CUSTOMERS,
  USER_LOADING,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,

	ACCOUNT_LOADED,
	ACCOUNT_LOADING
} from '../constants'

import {
	unsubscribe,
	fetchTickets
} from './ticket'

/**
 * Auto-login status detection
 */
export const detectLoginStatus = () => (dispatch, getState) =>
	onAuthStateChanged(auth, async user => {

		dispatch({
			type: USER_LOADING,
			data: true
		})

		if (!user)
			return dispatch({type: USER_LOGGED_OUT})

		// fetch user data
		let profile = await getDoc(doc(db, CUSTOMERS, user.uid))
			.then(snapshot => snapshot.exists() ? snapshot.data() : {})
			.catch(() => ({}))

		if (!profile.objectId)
			return dispatch(logout())

		// get saved account
		const {tickets, account} = getState()

		if (!account.id) {

			let accountId

			try {
				// get account id
				accountId = localStorage.getItem('account') || profile.access[0]
			} catch (err) {
				return dispatch(logout())
			}

			// get saved account or fallback to first with access
			accountId = localStorage.getItem('account') || profile.access[0]

			if (!accountId)
				return dispatch(logout())

			await dispatch(fetchAccount(accountId))

		}

		dispatch({
			type: USER_LOGGED_IN,
			data: profile
		})

		dispatch(fetchTickets(tickets.status, true))

 })

/**
 * Authentication method
 * @param {object} data Login credentials
 */
export const login = data => async dispatch => {

	let accountId = data.account.trim().toLowerCase(),
		username = data.username.trim().toLowerCase(),
		password = data.password

	if (!accountId.length || !username.length || !password.length)
		return  dispatch(loginFailed('All fields are required'))

	dispatch({
		type: USER_LOADING,
		data: true
	})

	let response = await request('/support/login', {accountId, username, password}, false)

	if (response.error)
		return dispatch(loginFailed(response.error))

	// save account
	localStorage.setItem('account', accountId)

	// sign in
	signInWithCustomToken(auth, response.token)
		.catch(err => dispatch(loginFailed(err.code)))

}

/**
 * Login failed
 * @param {string} message Error message
 * @returns {string}
 */
function loginFailed(message) {
	showMessage(message)
	return {
		type: USER_LOADING,
		data: false
	}
}

/**
 * Logout method
 */
export const logout = () => () => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return window.location.href = '/'

	// unbind listeners
	Object.keys(unsubscribe).forEach(prop => {
		unsubscribe[prop]()
		delete unsubscribe[prop]
	})

	signOut(auth).then(() => window.location.href = '/')

}

/**
 * Account switcher
 * @param {string} accountId Account identifier
 */
export const switchAccount = accountId => (_, getState) => {

	const {account} = getState()

	if (account.id === account)
		return false

	// save account
	localStorage.setItem('account', accountId)

	// reload page
	window.location.reload()

}

/**
 * Fetch customer profile data
 * @param {string} objectId Unique identifier
 */
export const fetchCustomer = objectId => async () => {

	let profile = await getDoc(doc(db, CUSTOMERS, objectId))
		.then(snapshot => snapshot.exists() ? snapshot.data() : {})
		.catch(() => ({}))

	return profile

}

/**
 * Search customers method
 * @param {string} filter Text criteria
 * @param {function} modifier Results method
 * @returns {array} List of matching accounts
 */
export const searchCustomers = (filter = '', modifier = null) => async (_, getState) => {

	if (!filter.length)
		return []

	const {account} = getState()

	let response = await request('/customer/search', {
		filter,
		accountId: account.id,
	})

	if (response.error) {
		showMessage(response.error)
		return []
	}

	if (modifier)
		return response.list.map(item => modifier(item))

	return response.list

}

/**
 * Fetch data in batches
 */
export const fetchBatch = data => async (_, getState) => {

	const {account} = getState()

	// get amount of entries to fetch
	let perRequest = data.amount || 15

	// build fetch query
	let q = query(
		collection(db, data.collection),
		where('account', '==', account.id)
	)

	// append filter (if any)
	if (data.field && data.value)
		q = query(q, where(data.field, '==', data.value))

	q = query(q,
		limit(perRequest),
		orderBy(data.orderBy, data.sortType)
	)

	// append last entry (if any)
	if (data.last)
		q = query(q, startAfter(data.last[data.orderBy]))

	// return executed query
	return getDocs(q).then(snapshot => {

		if (snapshot.empty)
			return {noMoreToLoad: true, list: []}

		let list = snapshot.docs.map(doc => doc.data())

		return {
			list,
			noMoreToLoad: list.length < perRequest
		}

	})

}

/**
 * Start a subscription for a customer
 * @param {string} customerId Unique customer identifier
 * @param {object} option Subscription details
 */
export const startSubscription = (customerId, option) => dispatch => {

	// confirm prompt
	if (!window.confirm('Are you sure you want to start this subscription?'))
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})

	let now = moment(),
		subscription = {
			...option,
			rebill: false,
			createdAt: now.toDate(),
			account: option.account,
			currency: option.currency,
			rebillAt: now.add(30, 'days').toDate(),
			minutesLeft: option.minutesDaily || option.minutesTotal
		}

	// append limited unlocks (if any)
	if (option.unlocksTotal)
		subscription.unlocksLeft = option.unlocksTotal

	// remove unnecessary props
	delete subscription.active

	// create subscription
	return updateDoc(doc(db, CUSTOMERS, customerId), {
			subscription,
			[`funds.${option.currency}`]: increment(option.price * -1)
		})
		.then(() => window.location.reload())
		.catch(err => showMessage(err.message))
		.finally(() => dispatch({
			type: USER_LOADING,
			data: false
		}))

}

/**
 * Update user wallet method
 * @param {string} objectId Unique user identifier
 * @param {float} amount Amount to update the wallet with
 * @param {function} callback Method to trigger on success
 */
 export const updateFunds = (objectId, amount, callback) => async (dispatch, getState) => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	// set to loading
	dispatch({
		type: USER_LOADING,
		data: true
	})

	// define fields
	const {account} = getState()

	// save new transaction
	return updateDoc(doc(db, CUSTOMERS, objectId), {[`funds.${account.currency}`]: amount})
		.then(() => callback({[account.currency]: amount}))
		.catch(err => showMessage(err.message))
		.finally(() => dispatch({
			type: USER_LOADING,
			data: false
		}))

}

/**
 * Refund payment charge
 * @param {object} transaction Transaction details
 */
export const refund = (transaction, callback) => async dispatch => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	if (!window.confirm('Are you sure?'))
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})

	let response = await request('/payments/refund', {transactionId: transaction.objectId})

	dispatch({
		type: USER_LOADING,
		data: false
	})

	if (response.error) {

		if (response.error === 'charge_already_refunded' || response.error === 'charge_disputed')
			callback(transaction.objectId)

		return showMessage(response.error)

	}

	callback(transaction.objectId, response.transaction)
	showMessage('Successfully refunded', 'success')

}

/**
 * Fetch account info
 * @param {string} accountId Account identifier
 */
export const fetchAccount = accountId => async (dispatch, getState) => {

	const {account} = getState()

	if (account.loading)
		return false

	dispatch({
		type: ACCOUNT_LOADING,
		data: true
	})

	let response = await request('/account', {accountId})

	dispatch({
		type: ACCOUNT_LOADING,
		data: false
	})

	if (response.error)
		dispatch(logout())

	dispatch({
		type: ACCOUNT_LOADED,
		data: response.account
	})

}

/**
 * Settings update for customer profile
 * @param {string} objectId Unique user identifier
 * @param {object} data Properties to update
 * @param {[function]} callback Method to trigger on success
 * @returns Promise
 */
export const updateCustomerInfo = (objectId, data, callback) => async dispatch => {

	const {uid} = auth.currentUser || {}

	if (!uid)
		return false

	dispatch({
		type: USER_LOADING,
		data: true
	})

	return updateDoc(doc(db, CUSTOMERS, objectId), data)
		.then(() => {

			dispatch({
				type: USER_LOADING,
				data: false
			})

			if (callback)
				callback(data)

		})
		.catch(() => {
			dispatch({
				type: USER_LOADING,
				data: false
			})
		})

}
