export const DOMAIN 				              = 'flexbox.ro'
export const API_URL 				              = 'https://api.flexbox.ro'

export const FIREBASE_CONFIG              = {
	authDomain: DOMAIN,
  projectId: 'ride-ottr',
  messagingSenderId: '467155726739',
  storageBucket: 'ride-ottr.appspot.com',
	apiKey: 'AIzaSyDfAxEhBKvo24DzONruDEgO9N4tQRaNRac',
  appId: '1:467155726739:web:06c3ee99938859f662f866',
	recaptchaKey: '6Ldz6p8jAAAAAIJRpNjfuz1WOUMQJ5pBRmPlFWXX'
}

export const ACCOUNT_LOADING 							= 'ACCOUNT_LOADING'
export const ACCOUNT_LOADED 							= 'ACCOUNT_LOADED'

export const ATTACHMENT_MIMES							= ['image/png', 'image/jpeg', 'video/mp4']

export const REPLIES 											= 'replies'
export const REPLIES_LOADED								= 'REPLIES_LOADED'
export const REPLIES_RESET 								= 'REPLIES_RESET'
export const REPLIES_LOADING							= 'REPLIES_LOADING'
export const REPLIES_NEW_ITEM 						= 'REPLIES_NEW_ITEM'
export const REPLIES_REMOVE_ITEM 					= 'REPLIES_REMOVE_ITEM'
export const REPLIES_LOADED_ALL 					= 'REPLIES_LOADED_ALL'
export const REPLIES_LOADED_MORE 					= 'REPLIES_LOADED_MORE'
export const REPLIES_PER_REQUEST 					= 10

export const TICKETS 											= 'tickets'
export const TICKETS_LOADED 							= 'TICKETS_LOADED'
export const TICKETS_LOADING 							= 'TICKETS_LOADING'
export const TICKETS_STATUS 							= 'TICKETS_STATUS'
export const TICKETS_RESET 								= 'TICKETS_RESET'
export const TICKETS_NEW_ITEM 						= 'TICKETS_NEW_ITEM'
export const TICKETS_UPDATED_ITEM 				= 'TICKETS_UPDATED_ITEM'
export const TICKETS_REMOVE_ITEM 					= 'TICKETS_REMOVE_ITEM'
export const TICKETS_LOADED_ALL 					= 'TICKETS_LOADED_ALL'
export const TICKETS_LOADED_MORE 					= 'TICKETS_LOADED_MORE'
export const TICKETS_PER_REQUEST 					= 15

export const LOGS 												= 'logs'

export const CUSTOMERS 										= 'users'
export const USER_LOADING 					      = 'USER_LOADING'
export const USER_LOGGED_IN 							= 'USER_LOGGED_IN'
export const USER_LOGGED_OUT 							= 'USER_LOGGED_OUT'
