import {
	ACCOUNT_LOADING,
	ACCOUNT_LOADED
} from '../constants'

const initialState = {
	loading: false
}

export default function Account(state = initialState, action) {
	switch (action.type) {
		case ACCOUNT_LOADING:
			return {
				...state,
				loading: action.data
			}
		case ACCOUNT_LOADED:
			return {
				loading: false,
				...action.data
			}
		default:
			return state
	}
}