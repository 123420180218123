import {
  REPLIES_RESET,
  REPLIES_LOADED,
  REPLIES_LOADING,
  REPLIES_NEW_ITEM,
  REPLIES_REMOVE_ITEM,
  REPLIES_LOADED_ALL,
  REPLIES_LOADED_MORE
} from '../constants'

const initialState = {
  list: [],
  loading: false
}

export default function Replies(state = initialState, action) {

  switch (action.type) {
    case REPLIES_RESET:
      return initialState
    case REPLIES_LOADED:
      return {
        ...state,
        loading: false,
        list: action.data || []
      }
    case REPLIES_LOADING:
      return {
        ...state,
        loading: true
      }
    case REPLIES_NEW_ITEM:
      return {
        ...state,
        list: [...state.list, action.data]
      }
    case REPLIES_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter(item => item.objectId !== action.data)
			}
    case REPLIES_LOADED_ALL:
      return {
        ...state,
				loading: false,
        noMoreToLoad: true
      }
    case REPLIES_LOADED_MORE:
      return {
        ...state,
        loading: false,
        list: [...action.data, ...state.list]
      }
    default:
      return state
  }

}