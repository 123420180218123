import TextareaAutosize from 'react-textarea-autosize'
import {useSelector, useDispatch} from 'react-redux'
import {searchCustomers} from '../../../actions/user'
import {Link, useNavigate} from 'react-router-dom'
import {createTicket} from '../../../actions/ticket'
import AsyncSelect from 'react-select/async'
import {useState} from 'react'

export default function Create() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const user = useSelector(state => state.user)

	const [state, changeState] = useState({
    owner: '',
    message: ''
  })

	const setState = data => changeState(prevState => ({...prevState, ...data}))

  const onSetCustomer = item => {

		if (!item)
			return false

		setState({
      owner: item.value,
      name: item.label
    })

	}

  const onRemoveCustomer = () => setState({owner: ''})

	const onCreate = () => {

    if (user.loading)
      return false

    dispatch(createTicket({
      operator: true,
      owner: state.owner,
      message: state.message,
			subject: 'Operator'
    }, navigate))

  }

	return (

		<>
			<div className="mb-3">
				<div className="d-flex align-items-center">
					<Link to="/" className="btn btn-sm btn-outline-secondary me-2">
						<i className="fas fa-fw fa-chevron-left" />
					</Link>
					<b className="text-overflow">Create ticket</b>
				</div>
			</div>

      <div className="box my-2">

        <TextareaAutosize
          minRows={2}
          maxRows={7}
          value={state.message}
          placeholder="Write message"
          style={{resize: 'none'}}
          className="border-0 p-3 w-100"
          onChange={e => setState({message: e.target.value})}
        />

        <hr className="bg-secondary bg-opacity-75 m-0" />

        <div className="d-flex align-items-center justify-content-between bg-light p-2">
          {state.owner ?
            <div className="input-group">
              <button className="btn btn-danger" onClick={onRemoveCustomer} disabled={user.loading}>
                <i className="fal fa-fw fa-times" />
              </button>
              <div className="input-group-text">
                {state.name}
              </div>
            </div>
            :
            <AsyncSelect
              className="flex-grow-1 me-2"
              isClearable={true}
              placeholder="Search customer"
              onChange={onSetCustomer}
              isLoading={state.loading}
              loadOptions={inputValue => dispatch(searchCustomers(inputValue, item => ({value: item.objectId, label: item.billing.holder})))}
            />
          }
          <button className="btn btn-success" disabled={user.loading || !state.message.trim().length || !state.owner.length} onClick={onCreate}>SEND</button>
        </div>
      </div>

		</>
	)

}
