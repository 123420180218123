import {Link, useNavigate, useParams} from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect, useRef, useState} from 'react'
import {showMessage} from '../../../utils'
import moment from 'moment'

import {
	REPLIES_RESET,
	ATTACHMENT_MIMES
} from '../../../constants'

import {
	reply,
	removeReply,
	fetchTicket,
	unloadTicket,
	fetchReplies,
	toggleStatus,
	getPreviousTickets
} from '../../../actions/ticket'

export default function Ticket() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const fileRef = useRef()
	const inputRef = useRef()
	const wasLoaded = useRef()
	const attachment = useRef()
	const {ticketId} = useParams()

	const user = useSelector(state => state.user)
	const tickets = useSelector(state => state.tickets)
	const replies = useSelector(state => state.replies)

	const [state, changeState] = useState({
		target: 'en',
		message: '',
		loading: true,
		attachment: null,
		showPrevious: false,
		previousTickets: []
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	useEffect(() => {

		dispatch(fetchTicket(ticketId, navigate, loaded => {
			wasLoaded.current = loaded
			dispatch(fetchReplies(ticketId, true))
		}))

		return () => {
			dispatch({type: REPLIES_RESET})
			if (wasLoaded.current)
				dispatch(unloadTicket(ticketId))
		}

	}, [dispatch, navigate, ticketId]) // eslint-disable-line

	useEffect(() => {

		if (state.showPrevious !== ticketId)
				setState({showPrevious: false})
		else if (state.showPrevious && !state.previousTickets.length)
			dispatch(getPreviousTickets(ticketId, data => setState(data)))

	}, [dispatch, ticketId, state.showPrevious, state.previousTickets])

	const onToggleStatus = () => {
		const status = ticket.status === 'open' ? 'closed' : 'open'
		dispatch(toggleStatus(ticketId, status, navigate))
	}

	const onAttachmentChange = async e => {

		const file = e.target.files[0]
		e.target.value = null

		if (!file)
			return false

		if (!ATTACHMENT_MIMES.includes(file.type))
			return showMessage('Only JPG and PNG files are supported! Try again.')

		setState({attachment: `File (${(file.size/1048576).toFixed(2)}MB)`})
		attachment.current = file

	}

	const onRemoveAttachment = () => {
		attachment.current = null
		setState({attachment: null})
	}

	const onReply = () => dispatch(reply({
		operator: true,
		objectId: ticketId,
		message: state.message,
		attachment: state.attachment ? attachment.current : null
	}, data => setState(data)))

	// find ticket
	let ticket = tickets.list.find(item => item.objectId === ticketId) || {}

	// ticket does not exist or user user doesn't have access to it
	if (tickets.loading || !ticket.objectId)
		return (
			<div className="box p-5 text-center">
				<div className="spinner-border" />
			</div>
		)

	return (

		<>

			{!!state.showPrevious &&
				<>
				<ul className="nav nav-tabs">
					<li className="nav-item">
						<span className="nav-link active">Other tickets</span>
					</li>
				</ul>
					<div className="box list-group-flush noTop mb-3" data-empty="No other tickets created">
						{state.loading ?
							<div className="text-center p-3">
								<i className="fas fa-spin fa-spinner-third" />
							</div>
							:
							state.previousTickets.filter(item => item.objectId !== ticketId).map(item =>
								<Link key={item.objectId} to={'/tickets/' + item.objectId} className="list-group-item-action px-3 py-2 d-flex align-items-center">
									<div className="flex-grow-1 text-overflow">
										<b>{item.subject}</b>
										<br />
										<small className="text-muted">{item.lastReply}</small>
									</div>
									<div className="text-end flex-shrink-0">
										{moment(item.lastReplyAt.toDate()).format('D MMM YYYY')}
										<br />
										<small className={item.lastReplyBy !== user.objectId ? '' : 'text-muted'}>{moment(item.createdAt.toDate()).format('D MMM YYYY')}</small>
									</div>
								</Link>
							)
						}
					</div>
				</>
			}

			<div className="box p-3 mb-3">
				<div className="d-flex align-items-center">

					<Link to="/tickets" className="me-1">Tickets</Link> / <span className="text-overflow mx-1">{ticket.subject || 'Loading'}</span>

					<div className="separator" />

					<button className={'btn btn-sm me-2 ' + (state.showPrevious ? 'btn-secondary' : 'btn-outline-secondary')} onClick={() => setState({showPrevious: state.showPrevious ? false : ticket.objectId})}>
						<i className="fas fa-clock-rotate-left" />
					</button>

					<Link to={`/customers/${ticket.owner}`} className="btn btn-sm btn-outline-primary me-2">
						<i className="fas fa-user" />
					</Link>

					<button className="btn btn-sm btn-outline-secondary" onClick={onToggleStatus} disabled={user.loading}>
						{ticket.status === 'open' ? 'CLOSE' : 'OPEN'}
					</button>

				</div>
			</div>

			{/* LOAD PREVIOUS */}
			{!replies.noMoreToLoad &&
				<div className="text-center mb-3">
					<button className="btn btn-sm btn-outline-secondary me-2" onClick={() => dispatch(fetchReplies(ticketId))} disabled={replies.loading}>
						{replies.loading ? <i className="fas fa-spin fa-spinner-third" /> : 'load previous'}
					</button>
				</div>
			}

			<div className="d-flex flex-column">
				{/* REPLIES LISTING */}
				{replies.list.map((item, idx) => {

					let isOperator = !!item.operator
					let isSelf = item.operator === user.objectId

					return (
						<div key={item.objectId} className={'card reply-message mb-2' + (isOperator ? ' text-white bg-dark align-self-end text-end' : '')}>
							<div className={`card-header bg-gradient d-flex align-items-center ${isOperator ? '' : 'pe-2'}`}>

								{/* ATTACHMENT */}
								{item.attachment &&
									<button
										href={item.attachment}
										data-hash="false"
										data-protect="true"
										data-fancybox="gallery"
										data-back-focus={false}
										data-animation-effect="fade"
										disabled={user.loading || replies.length <= 1}
										className="btn btn-sm btn-primary bg-gradient me-2"
									>
										<i className="far fa-paperclip" />
									</button>
								}
								{/* SENDER */}
								<b>{isOperator ? (isSelf ? 'You' : 'Operator') : 'Customer'}</b>

								<div className="separator" />

								{/* TIMESTAMP */}
								<small className="opacity-50">{moment(item.createdAt.toDate()).fromNow()}</small>

								{/* CONTROLS */}
								{(item.operator && idx === replies.list.length - 1) &&
									<button className="btn btn-sm btn-outline-light ms-2" disabled={user.loading || replies.length <= 1} onClick={() => dispatch(removeReply(ticket, item))}>
										<i className="far fa-fw fa-trash-alt" />
									</button>
								}

							</div>
							<div className="card-body">
								<p className="card-text pre-line">{item.message.replace(/\n\s*\n/g, '\n')}</p>
							</div>
						</div>
					)

				})}

			<hr />

			<div className="box my-2">
				<TextareaAutosize
					ref={inputRef}
					minRows={2}
					maxRows={7}
					value={state.message}
					placeholder="Write message .."
					style={{resize: 'none'}}
					className="border-0 p-3 w-100"
					onChange={e => setState({message: e.target.value})}
				/>
				<hr className="bg-success bg-opacity-75 m-0" />
				<div className="d-flex align-items-center bg-light p-2">
					{state.attachment ?
						<div className="input-group input-group-sm">
							<button className="btn btn-danger" onClick={onRemoveAttachment} disabled={user.loading}>
								<i className="fal fa-fw fa-times" />
							</button>
							<div className="input-group-text">
								{user.loading ? `Uploading (${state.progress}%)` : state.attachment}
							</div>
						</div>
						:
						<label className="btn btn-sm btn-outline-secondary" disabled={user.loading}>
							<input ref={fileRef} type="file" className="d-none" accept={ATTACHMENT_MIMES} onChange={onAttachmentChange} />
							<i className="fal fa-paperclip" />
						</label>
					}
					<div className="separator" />
					<button className="btn btn-sm btn-success" disabled={user.loading || !state.message.trim().length} onClick={onReply}>REPLY</button>
				</div>
			</div>

			</div>

		</>
	)

}
