import {BrowserRouter, Routes, Route, Link, NavLink, Navigate} from 'react-router-dom'
import {Provider, useDispatch, useSelector} from 'react-redux'
import {detectLoginStatus} from './actions/user'
import {ToastContainer} from 'react-toastify'
import {createRoot} from 'react-dom/client'
import {useEffect, useState} from 'react'
import store from './reducers'

// components
import Customers from './components/Customers'
import Tickets from './components/Tickets'
import Logout from './components/Logout'
import Connect from './components/Connect'

// import css
import 'react-toastify/dist/ReactToastify.min.css'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

// disable react-dev-tools
if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object")
	for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__))
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {} : null;

window.jQuery = require('jquery')
window.fancybox = require('@fancyapps/fancybox')

function App() {

	const dispatch = useDispatch()

	const user = useSelector(state => state.user)
	const account = useSelector(state => state.account)

	const [accountId, setAccountId] = useState(null)

	useEffect(() => {
		setAccountId(account.id)
	}, [account.id])

	useEffect(() => {
		dispatch(detectLoginStatus())
	}, [dispatch])

	// user is not authenticated
	if (!user.objectId)
		return <Connect />

	return (
		<div className="container-fluid py-3">

			{/* HEADER */}
			<div className="box p-2 d-flex mb-3 align-items-center">
				<div className="ps-2">
					<b>{account.name}</b> / {user.billing.holder}
				</div>
				<div className="separator" />
				{user.access.length > 1 &&
					<select className="form-select form-select-sm me-2" style={{flex: 'unset', width: 'auto'}} value={accountId} onChange={e => setAccountId(e.target.value)} disabled={user.loading}>
						{user.access.map(id =>
							<option key={id} value={id}>{id.toUpperCase()}</option>
							)}
					</select>
				}
				<Link to="logout" className="btn btn-sm btn-danger">Logout</Link>
			</div>

			<div className="row">
				<div className="col-12 col-md-3">
					<ul className="list-group mb-3">
						<NavLink className="list-group-item" to="/tickets">
							<i className="fas fa-fw fa-headset" /> Tickets
						</NavLink>
						<NavLink className="list-group-item" to="/customers">
							<i className="fas fa-fw fa-user-friends" /> Customers
						</NavLink>
					</ul>
				</div>
				<div className="col-12 col-md-9">
					<Routes>
						<Route path="/tickets/*" element={<Tickets />} />
						<Route path="/customers/*" element={<Customers />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="*" element={<Navigate to="/tickets" />} />
					</Routes>
				</div>
			</div>

		</div>
	)

}

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
			<ToastContainer />
		</BrowserRouter>
	</Provider>
)