import {useDispatch, useSelector} from 'react-redux'
import {fetchTickets} from '../../../actions/ticket'
import {Link} from 'react-router-dom'
import moment from 'moment'

export default function List() {

  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const tickets = useSelector(state => state.tickets)

  return (
    <>
      <ul className="nav nav-tabs align-items-center">
        <li className="nav-item" onClick={() => dispatch(fetchTickets('open', true))}>
          <span className={'nav-link' + (tickets.status === 'open' ? ' active' : '')}>Open</span>
        </li>
        <li className="nav-item" onClick={() => dispatch(fetchTickets('closed', true))}>
          <span className={'nav-link' + (tickets.status === 'closed' ? ' active' : '')}>Closed</span>
        </li>
        <div className="separator" />
        <li className="nav-item">
          <Link to="/tickets/create" className="btn btn-sm btn-success">New</Link>
        </li>
      </ul>

      <div className="box list-group-flush noTop mb-3">
        {!!tickets.list.length && tickets.list.map(item => {

          let isNew = item.lastReplyBy !== user.objectId

          return (
            <Link key={item.objectId} to={'/tickets/' + item.objectId} className="list-group-item-action px-3 py-2 d-flex align-items-center">
              <div className="flex-grow-1 text-overflow">
                <b>{item.subject}</b>
                <br />
                <small className="text-muted">{item.lastReply}</small>
              </div>
              <div className="text-end flex-shrink-0">
                {moment(item.lastReplyAt.toDate()).format('D MMM YYYY')}
                <br />
                <small className={isNew ? '' : 'text-muted'}>{moment(item.createdAt.toDate()).format('D MMM YYYY')}</small>
              </div>
            </Link>
          )

        })}

        {(!tickets.list.length && tickets.noMoreToLoad) &&
					<div className="text-danger p-3">No tickets to show yet</div>
				}

      </div>

      {!tickets.noMoreToLoad &&
				<div className="text-center p-3">
					<button className="btn btn-sm btn-primary" onClick={() => dispatch(fetchTickets(tickets.status))} disabled={tickets.loading}>
						{tickets.loading ? <i className="fas fa-spin fa-spinner-third" /> : 'load more'}
					</button>
				</div>
			}

    </>
  )

}