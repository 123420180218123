import {Routes, Route, NavLink} from 'react-router-dom'

import Search from './Search'
import Customer from './Customer'

export default function Customers() {
  return (
    <>
      <ul className="nav nav-tabs">
				<NavLink to="" end className="nav-link">Search</NavLink>
			</ul>

      <Routes>
        <Route path=":objectId" element={<Customer />} />
        <Route index element={<Search />} />
      </Routes>
    </>
  )
}