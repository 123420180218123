import {searchCustomers} from '../../../actions/user'
import parsePhoneNumber from 'libphonenumber-js'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useState} from 'react'

export default function Search() {

	const dispatch = useDispatch()

	const [state, changeState] = useState({
		list: [],
		filter: '',
		loading: false
	})

	const setState = data => changeState(prevState => ({...prevState, ...data}))

	const onSubmit = async () => {

		setState({loading: true})
		let list = await dispatch(searchCustomers(state.filter))
		setState({list, loading: false})

 }

	return (
		<>

			<div className="box noTop mb-3 p-3">
				<div className="input-group">
					<input type="text" className="form-control" placeholder="Name, email, phone number" value={state.filter} onChange={e => setState({filter: e.target.value})} />
					<button className="btn btn-primary" onClick={onSubmit} disabled={state.loading}>Search</button>
				</div>
			</div>

			<div className="list-group">
				{state.list.map(item =>
					<div className="list-group-item d-flex align-items-center" key={item.objectId}>

						<div>
							<Link to={'/customers/' + item.objectId}>{item.billing.holder}</Link>
							<div className="text-muted">{item.billing.email}</div>
						</div>

						<div className="separator" />

						<div className="text-end">
							<small className="text-muted">
								{parsePhoneNumber(item.phoneNumber).formatInternational()}
							</small>
						</div>

					</div>
				)}

				{(!state.list.length && !state.loading) &&
					<div className="list-group-item text-danger">No customers matching your criteria</div>
				}

				{state.loading &&
					<div className="list-group-item text-center">
						<i className="fas fa-spin fa-spinner-third" />
					</div>
				}

			</div>

		</>
 )

}