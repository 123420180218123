import {
	TICKETS_LOADED,
	TICKETS_LOADING,
	TICKETS_RESET,
	TICKETS_STATUS,
	TICKETS_NEW_ITEM,
	TICKETS_REMOVE_ITEM,
	TICKETS_UPDATED_ITEM,
	TICKETS_LOADED_ALL,
	TICKETS_LOADED_MORE,
} from '../constants'

const initialState = {
	loading: false,
  status: 'open',
	list: []
}

export default function Tickets(state = initialState, action) {
	switch (action.type) {
		case TICKETS_RESET:
			return {
				...state,
				list: [],
				loading: true,
				noMoreToLoad: false
			}
		case TICKETS_STATUS:
			return {
				...state,
				list: [],
				loading: false,
				status: action.data
			}
		case TICKETS_LOADED:
			return {
				...state,
				loading: false,
				list: action.data || []
			}
		case TICKETS_LOADING:
			return {
				...state,
				loading: true
			}
		case TICKETS_LOADED_MORE:
			return {
				...state,
				loading: false,
				list: [...state.list, ...action.data.filter(item => state.list.find(ticket => ticket.objectId !== item.objectId))]
			}
		case TICKETS_LOADED_ALL:
			return {
				...state,
				loading: false,
				noMoreToLoad: true
			}
		case TICKETS_NEW_ITEM:
			return {
				...state,
				list: [action.data, ...state.list.filter(item => item.objectId !== action.data.objectId)]
			}
		case TICKETS_UPDATED_ITEM:
			return {
				...state,
				list: state.list.map(item => {

					if (item.objectId === action.data.objectId)
						return {
							...item,
							...action.data
						}

					return item

				})
			}
		case TICKETS_REMOVE_ITEM:
			return {
				...state,
				list: state.list.filter(item => item.objectId !== action.data)
			}
		default:
			return state
	}
}